import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { MrGreenTable } from '../components/toplist';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`MrGreen`}</h1>
    <p>{`MrGreen säger själva att dom är ett skönare nätcasino. Vi har gått igenom deras sida och vänt upp och ner på varenda sten för att undersöka om deras påstående har någon sanning i sig.`}</p>
    <MrGreenTable mdxType="MrGreenTable" />
    <h1>{`MrGreen Historia`}</h1>
    <p>{`MrGreen blev grundat i 2008 vilket gör dom till ett av dom yngsta bolagen inom nätcasino branchen. De tre som är grundarna av MrGreen är Fredrik Sidfalks, Henrik Bergquist och Mikael Pawlo, de har alla tre jobbat många år inom spelbranchen och visste att för att skapa ett nytt Casino så behövde dom göra något revolutionerade för sina spelare. Både Fredrik och Henrik var med och grundade Betsson ihop med Pontus Lindwall, efter ett par år så valde dom att sälja ut sig från Betsson men de kunde inte hålla sig borta från spelbranchen och kom därför tillbaka med en ny sida som blev MrGreen.`}</p>
    <h1>{`MrGreen Utveckling`}</h1>
    <p>{`Sedan starten i 2008 så har MrGreen alltid jobbat hårt med att förbättre spelupplevelsen för sina spelare, detta har dom gjort igenom att spendera mycket tid åt att analysera den data som de får in igenom sina spelares aktivitet på sidan och sedan igenom detta försöka att göra olika former av förbättringar. Deras sida har många gånger igenom åren blivit omgjorda för att förbättra upplevelsen för kunden. Deras hårda arbete med att förbättra sidan för att göra deras kunder nöjda har gett utdelning då de har vid fyra tillfällen blivit utnämnd till årets nätcasino av tidningen Internetworld som årligen rankar de 100 bästa sidorna online.`}</p>
    <h1>{`MrGreen ett skönare nätcasino?`}</h1>
    <p>{`Som MrGreen hävdar själva så är dom ett skönare nätcasino än många av sina konkurrenter och det är något som vi kan hålla med dom om, då deras sida är bra utformad och man kommer snabbt fram till sina favorit spel och det är nästan som om MrGreen vet om vilket som är mitt favorit spel då jag kan hitta det väldigt enkelt. När man går till ett Casinospel så vissa det fram mycket elegant på skärmen och spelet tar nästan upp hela skärmen och det spelar ingen roll vilken dator jag testar det på, det visas alltid fram på det mest optimala sättet för min dator. När man läster om MrGreen på deras sida och de många artiklar som de skriver så får man en känsla av de som driver MrGreen vill bara sina spelare det bästa och gör allt dom kan för att just erbjuda ett skönare nätcasino. Med deras alla härliga funktioner och välskrivna texter som är skrivna på ett underhållande sätt så skulle jag säga att MrGreen är absoult ett skönare nätcasino.`}</p>
    <h1>{`MrGreen Support`}</h1>
    <p>{`Man kommer enkelt i kontakt med deras support igenom att klicka på hjälp som man ser tydligt på den vänstra sidan av skärmen när man är på sidan. Det kommer fram en meny där man kan välja mellan att skicka ett email, prata med dom live igenom chat eller deras telefon nummer om man vill slå dom en signal. Deras öppetider är från 07:00 – 23:00 och dom har svensk support som är trevliga och mycket hjälpsamma. Vad vi tycker om med deras support sida är deras chat som är integrerat på ett snyggt sätt med deras support sida, på många andra spelbolag så hoppar det istället upp en chatruta som kan upplevas något krånglig att ha en chat igenom.`}</p>
    <h1>{`Bonus och freespins`}</h1>
    <p>{`Som ny kund på MrGreen så får du ta del av en insättning bonus på 100% upp till 1000kr och så får du 10 free spins i ett Casinospel. Detta är väldigt starkt erbjudande och det kommer inte att sluta med detta erbjudande när du blir kund utan du kommer att få många erbjudande av MrGreen som kan vara freespins, insättningsbonusar och gratispengar.`}</p>
    <h1>{`Sammanfattning`}</h1>
    <p>{`MrGreen är ett svenskt nätcasino som vi kan rekommendera starkt då deras hårda jobb med att erbjuda kunden en bättre spelupplevelse är något som vi kan hålla med. Dom ser sin sida som sin produkt och gör allt de kan för att förbättra sin produkt igenom de många nya funktioner de ger till sina spelare. Blir man kund hos dom så kommer man inte att bli missnöjd och man kommer hela tiden att bli positivt överraskad av deras nya funktioner och lösningar. Du kommer inte att bli bortglömd som spelare utan snarare mer uppskattad desto längre du är deras kund.`}</p>
    <MrGreenTable mdxType="MrGreenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      